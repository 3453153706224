import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

const Bio = () => {
  return (
    <ContainerCustom>
        <BioImage>
            <StaticImage
            src="../assets/images/help-christina.jpeg"
            width={500}
            alt="Bio"
            placeholder="tracedSVG"
            quality={40}
            />
        </BioImage>
        <BioButton>
          <h3>Vous souhaitez me contacter, ou vous avez un projet à réaliser ?</h3>
          <button onClick={()=>{navigate("/email")}}>Parlons-en </button>
        </BioButton>
    </ContainerCustom>
  )
}

export default Bio

const ContainerCustom = styled.div`
display: flex;
flex-direction: row;
  margin: 5rem ;
  color: rgba(69, 73, 69, 0.636);
      @media screen and (max-width: 768px) {
        flex-direction: column;
        margin: 0rem ;
        margin-top: 5rem ;
      }
  `
const BioImage = styled.div`
  flex: 50%;
  text-align: center;
  heigth: auto;
  display: block;
`
const BioButton = styled.div`
  flex: 50%;
  padding: 4rem;
  text-align: center;
  h3 {
    font-size: 1.2rem;
    color: #404640; 
        @media screen and (max-width: 768px) {
        color:black;
        }
    }
    button {
      padding: 25px 30px;
      margin-top: 30px;
      border-style: none;
      font-size: 19px;
      color: white;
      font-weight: bold;
      background-color: #404640;  
  
      &:hover {
        background: #ab9668  ;
        transform: translateY(-2px);
    }
`