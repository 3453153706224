import React from 'react'
import styled from 'styled-components'
import { StaticImage } from "gatsby-plugin-image"

const Bio = () => {
  return (
    <BioContainer>
        <TopLine>
            Christine G.
        </TopLine>
        <BioImage>
            <StaticImage
            src="../assets/images/bio-christina.jpeg"
            width={700}
            height={800}
            alt="Bio"
            placeholder="tracedSVG"
            quality={40}
            />
            <Line1>
            ARTISTE PEINTRE
            </Line1>
        </BioImage>
        <BioText>
        <h5>Née en 1963, de nationalité belge, je vis et travaille à Tournefeuille près de Toulouse, en France depuis 25 ans. </h5>
        <h5>Mon métier d'ostéopathe et d'énergéticienne chinoise me met en relation avec l’HOMME, LA TERRE et LE CIEL. L’impact de la nature, le mouvement et l’homme dans son environnement sont un fil conducteur dans mes pensées. L’observation et le ressenti intérieur se traduisent en dessin, ligne ou peinture gestuelle.</h5>
        <h5>Mon parcours artistique commence dès mon enfance par des cours d’art chez les frères Salésiens à Louvain, en sculpture et dessin. Après avoir suivi l'école artistique de Tournefeuille, je suis inscrite depuis 10 ans aux Beaux Arts de Toulouse comme élève externe </h5>
        <h5>Mon travail se caractérise par une recherche autour de la calligraphie du trait, l’impact de la lumière et le mouvement. Le médium et le format sont variés. Les gestes et le trait sont le prolongement de mon corps et l’expression de mon esprit.</h5>
        <h5>Je travaille in situ, à l'extérieur, en ville, dans le train, en pleine nature. Je sens le besoin d ‘imprégner l'atmosphère, la vie sur un dessin, un croquis ou une œuvre directement (cf. Thème "jardin"). Quand je rentre, je retravaille le sujet à l'atelier. Ma maison et mon jardin sont strictement liés. Le travail intérieur & extérieur sont en harmonie, mon secret intime s’exprime par mes gestes, mes pensées, les couleurs, les ombres et la lumière. Le sujet me guide et est un prétexte en même temps</h5>
          <H6>
            <h6>L’art est le reflet de soi avec les autres </h6>
            <h6>L’art est intime </h6>
            <h6>L'art est la nature, la nature est l’art </h6>
            <h6>L’art est l’homme </h6>
          </H6>
          </BioText>
    </BioContainer>
  )
}
export default Bio

const BioContainer = styled.div`
  min-height: 100vh;
  padding: 0rem calc((100vw - 1300px) / 2);
  color: rgba(69, 73, 69, 0.636);
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.0) 0%,
    rgba(0, 0, 0, 0.4) 100%
  )
  `
  const TopLine = styled.p`
    font-family: BetterGrade;  
    font-size: 10rem; 
    color : #404640;
    text-align: center;
    margin-bottom: 0.75rem;
      @media screen and (max-width: 768px) {
        margin: 0px;
        padding: 0px;
        font-size: 5rem
      }
`
const BioImage = styled.div`
  text-align: center;
  heigth: auto;
  display: block;
  position: relative;
      @media screen and (max-width: 768px) {
      margin-right: 30px;
      margin-left: 30px;
      }
`
const Line1 = styled.div`
  position: absolute;
  top: 3em;
  left: 9em;
  padding: 0.7rem 2rem;
  background-color: #ab9668;
  color: white;
  font-size: 0.9rem;
    @media screen and (max-width: 768px) {
      top: 3em;
      left: -2em;
      padding: 0.5rem;
      font-size: 12px;
}
`
const BioText = styled.div`
  color: rgba(69, 73, 69, 0.636);
  padding: 5rem 8rem;
  margin-left: 50px;
  
    @media screen and (max-width: 768px) {
      color: black;
      padding: 30px ;
      margin-left: 0px;
      font-weight: inherit;
    }
     h5 {
      text-align: center;
        color: white;
        font-size: 1rem;
        padding: 10px;
        margin-right: 90px;
        margin-left: 40px;
        font-weight: bold;
        @media screen and (max-width: 768px) {
          color : #404640;
          font-size: 1rem;
          margin-right: 0px;
          margin-left: 0px;
        }
      }
`
const H6 = styled.div`
margin-top: 5rem;
h6 {
  color : black;
  text-align: center;
  margin-top: 0px;
  font-weight: bold;
  font-size: 1.1rem;
  @media screen and (max-width: 768px) {
    color : white;
    padding: 0rem 1.5rem;
  }
  }
  `